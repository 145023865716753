@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	background: $color-black;
	font-family: "pragmatica-extended", sans-serif;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-white;

}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-purple;
	}
}

header {
	.container {
		align-items: center;
		background: $color-white;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		max-width: 100%;
		padding: rem(20) rem(40);

		@include phone-sm-down {
			padding-left: 15px;
			padding-right: 15px;
		}

		h1 {
			margin: 0;
		}

		img {
			max-width: rem(215);

			@include phone-sm-down {
				max-width: rem(150);
			}
		}

		.socials {
			list-style: none;
			margin: 0;
			padding: 0;

			> li {
				display: inline-block;
				font-size: rem(20);
				vertical-align: middle;

				> a {
					color: $color-black;

					@include hover-focus {
						color: $color-purple;
					}
				}

				+ li {
					margin-left: rem(10);
				}
			}
		}
	}
}

main {
	section {
		padding: rem(125) 0;

		@include phone-sm-down {
			padding: rem(90) 0;
		}

		&#cta {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			@include phone-sm-down {
				display: block;
				text-align: center;
			}

			> div {
				&:first-child {
					flex: 0 0 55%;

					img {
						margin: 0 auto;
					}
				}

				&:last-child {
					flex: 0 0 45%;
					padding-left: rem(40);

					@include phone-sm-down {
						margin-top: rem(40);
						padding-left: 0;
					}

					h2 {
						font-size: rem(28);
						font-weight: 700;
						line-height: 1;
						margin: 0;
						text-transform: uppercase;

						span {
							display: block;

							&:nth-child(1) {
								font-size: rem(500);
								font-size: rem(18);
								font-style: italic;
							}

							&:nth-child(2) {
								margin: rem(8) 0;
							}

							&:nth-child(3) {
								color: $color-purple;
								font-size: rem(14);
							}
						}
					}

					.btns {
						list-style: none;
						margin: rem(20) 0 rem(40) 0;
						padding: 0;

						> li {
							font-weight: rem(700);

							+ li {
								margin-top: rem(10);
							}

							> a {
								border: 2px solid $color-white;
								display: block;
								padding: rem(12) rem(16);
								text-align: center;
								text-transform: uppercase;
							}
						}
					}

					.feat {
						display: block;
						font-size: rem(18);
						font-weight: 700;
						margin-bottom: rem(20);
						text-transform: uppercase;
					}

					.feat-tracks {
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
						font-size: rem(14);
						font-style: italic;

						> ul {
							flex: 0 0 50%;
							list-style: none;
							margin: 0;
							padding: 0;

							> li {
								+ li {
									margin-top: rem(15);
								}
							}
						}
					}
				}
			}
		}

		&#video {
			border-bottom: 1px solid #D9D9D9;
			border-top: 1px solid $color-white;

			h2 {
				font-size: rem(18);
				margin: 0 0 rem(40) 0;
				text-transform: uppercase;
			}

			.embed-container {
				margin: 0 auto;
				max-width: rem(1000);
			}
		}

		&#tour {
			h2 {
				font-size: rem(18);
				margin: 0 0 rem(40) 0;
				text-transform: uppercase;
			}

			#events {
				letter-spacing: 1.6px;
				margin: 0 auto;
				max-width: rem(830);

				.tour-date {
					align-items: center;
					display: flex;
					padding: rem(25) 0;
					text-transform: uppercase;

					@include phone-down {
						display: block;
						line-height: 1.5;
						text-align: center;
					}

					+ .tour-date {
						border-top: 1px solid $color-white;
					}

					> div {
						&.tour-date-venue {
							flex: 0 0 30%;

							.tour-date-time {
								font-weight: 700;
							}

							.tour-venue {
								font-size: rem(14);
								margin-top: rem(10);

							}
						}

						&.tour-date-location {
							flex: 0 0 50%;
							font-weight: 700;
						}

						&.tour-date-tickets {
							flex: 0 0 20%;
							text-align: right;

							@include phone-down {
								text-align: center;
							}

							a {
								border: 2px solid $color-purple;
								color: $color-white;
								display: inline-block;
								font-weight: 700;
								padding: rem(10);
								text-align: center;
								vertical-align: middle;

								@include hover-focus {
									color: $color-purple;
								}

								+ a {
									margin-top: rem(10);
								}
							}
						}
					}
				}
			}
		}

		&#enter {
			text-align: center;
			text-transform: uppercase;

			a {
				font-size: rem(20);
				font-weight: 800;
				text-transform: uppercase;
			}
		}
	}
}

footer {
	background: url('../img/bg/Slash_Cover 2.png') center top no-repeat;
	background-size: cover;
	padding: rem(180) 0;
	text-align: center;

	@include phone-sm-down {
		padding: rem(90) 0;
	}

	img {
		margin: 0 auto rem(35) auto;
		max-width: rem(300);
	}

	.socials {
		list-style: none;
		margin: 0;
		padding: 0;

		> li {
			display: inline-block;
			font-size: rem(20);
			vertical-align: middle;

			+ li {
				margin-left: rem(10);
			}
		}
	}
}
